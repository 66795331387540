import { ResponsiveSheet } from 'components/ResponsiveSheet'
import { TableNumberController } from 'components/TableNumber/TableNumberController'
import { findSettingsByOrderingType } from 'components/Venue/utils/venueUtils'
import { useOrderingTypeContext } from 'contexts/VenueOrderContext'
import { useLogger } from 'hooks/useLogger'
import { CartLandingQuery, VenueLandingQuery } from 'lib/gql'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  open: boolean
  venue: VenueLandingQuery['guestVenue']
  cart: CartLandingQuery['getCart']
  onOpenChange: () => void
}

export const TableNumberSheet = ({
  open,
  venue,
  cart,
  onOpenChange,
}: Props) => {
  const { logEvent } = useLogger()

  const handleOnSubmit = useCallback(() => {
    logEvent('Updated table number on venue landing page')
    onOpenChange()
  }, [logEvent, onOpenChange])

  const { orderingType } = useOrderingTypeContext()
  const settings = findSettingsByOrderingType({ venue, orderingType })

  return (
    <ResponsiveSheet
      open={open}
      title={
        settings?.tablePromptText || (
          <FormattedMessage
            defaultMessage="What’s your table number?"
            id="eFKMTQ"
          />
        )
      }
      onOpenChange={onOpenChange}
    >
      <div className="p-4">
        <TableNumberController
          onClose={handleOnSubmit}
          number={cart?.tableNumber}
          area={cart?.tableArea}
        />
      </div>
    </ResponsiveSheet>
  )
}
