import { CenterSpinner } from '@mr-yum/frontend-ui'
import { ImageItem, ListItem } from 'components/MenuItem'
import { ItemLinkWrapper } from 'components/MenuItem/shared'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { useLogger } from 'hooks/useLogger'
import { CartLandingQuery, useMvoSearchQuery } from 'lib/gql'
import React, { useContext } from 'react'
import { useDebounce as reactUseDebounce } from 'react-use'
import { MenuStoreContext } from 'stores/MenuStore'

import { NoFilteredCategories } from '../Categories/NoFilteredCategories'
import {
  filterAndSortResults,
  isImageItem,
  isListItem,
  SearchResultsToDisplay,
} from '../utils/searchUtils'
import { CategorySearchDisplay } from './CategorySearchDisplay'

interface DisplayCategorySearchResultsProps {
  cart?: CartLandingQuery['getCart']
  debouncedCategorySearch: string
}

export const DisplayCategorySearchResults = ({
  cart,
  debouncedCategorySearch,
}: DisplayCategorySearchResultsProps) => {
  const { orderingType, orderingTypeSlug } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()

  const { logEvent } = useLogger()

  const { categorySearch } = useContext(MenuStoreContext)

  const [{ data, fetching }] = useMvoSearchQuery({
    pause: !debouncedCategorySearch,
    variables: {
      searchQuery: debouncedCategorySearch,
      venueSlug,
      orderingType,
      orderingWindowStartDate: cart?.orderingWindowStartDate,
      tableArea: cart?.tableArea,
      tableNumber: cart?.tableNumber,
    },
  })

  const vendors = data?.mvoVendorSearch

  const menuItems = data?.mvoItemSearch

  const categoriesToDisplay: SearchResultsToDisplay[] = filterAndSortResults(
    menuItems,
    vendors,
  )

  reactUseDebounce(
    () => {
      if (categorySearch.length > 0) {
        logEvent('Filtered venue menu category', {
          query: categorySearch,
        })
      }
    },
    250,
    [categorySearch],
  )

  if (debouncedCategorySearch.length === 0) {
    return null
  }

  if (fetching) {
    return <CenterSpinner />
  }

  return (
    <div>
      {categoriesToDisplay.length > 0 && (
        <div>
          {categoriesToDisplay.map((vendor) => (
            <div key={vendor.categoryId} data-testid="List" className="py-5">
              <CategorySearchDisplay vendor={vendor} />
              {vendor.menuItems && (
                <div data-testid="MenuItemList">
                  {vendor.menuItems.map((item) => (
                    <div key={item.id} className="pt-4">
                      <ItemLinkWrapper
                        menuItem={item}
                        pathData={{
                          venueSlug,
                          orderingTypeSlug,
                          category: vendor.categorySlug,
                          itemSlug: item.slug,
                          mvoSearch: true,
                        }}
                      >
                        {isImageItem(item) && (
                          <ImageItem
                            menuItem={item}
                            data-testid="positioned-item"
                          />
                        )}
                        {isListItem(item) && <ListItem menuItem={item} />}
                      </ItemLinkWrapper>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {categoriesToDisplay.length === 0 && <NoFilteredCategories />}
    </div>
  )
}
